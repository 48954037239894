import React from "react";
import { useNavigate } from "react-router-dom";
import offer from "../assets/2025offer-removebg-preview.png";
import { useState } from "react";

function LandingPage() {
  ////////////////////////////////
  const [productOffer, setProductOffer] = useState("New Year 2025 Offer ");
  const phoneNumber = "+971581864638";
  const message = `Hello, I want to order ${productOffer}`;

  ////////////////////////////////

  const navigate = useNavigate();
  return (
    <div className="Container">
      <div className="Content-Container">
        <div className="Content">
          <div className="offerDiv">
            <img src={offer} alt="offer" />

            <h2>
              🎉 ! Start 2025 with an Exclusive Offer! 🎉
              <span>1 Box for 1000 AED Only</span>
            </h2>

            <a
              className="buttonOffer"
              href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
                message
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Offer
            </a>
          </div>{" "}
          <h1>SMARTWHIPDXB</h1>
          <p>
            The original large cream charger to increase your operation speed
            and precision with the help of an intelligent pressure release tool.
          </p>
        </div>

        <input
          type="button"
          className="navigateBtn"
          onClick={() => navigate("/products")}
          value=" Products"
        />
      </div>
    </div>
  );
}

export default LandingPage;
